export const items = [
    {
        id: 1,
        image:require("../../assets/skills/reactjs.png"),
    },
    {
        id: 2,
        image:require("../../assets/skills/angular.png"),
    },
    {
        id: 3,
        image:require("../../assets/skills/spring.png"),
    },
    {
        id: 4,
        image:require("../../assets/skills/mongodb.png"),
    },
    {
        id: 5,
        image:require("../../assets/skills/mysql.png"),
    },
    {
        id: 6,
        image:require("../../assets/skills/kafka.png"),
    },
    {
        id: 7,
        image:require("../../assets/skills/git.png"),
    },
    {
        id: 8,
        image:require("../../assets/skills/linux.png"),
    },
  ];